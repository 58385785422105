import { GatsbySeo } from 'gatsby-plugin-next-seo'

import NatalComponent from './components'
import './styles.scss'

function natal() {
  return (
    <>
      <GatsbySeo
        title="Natal na Decathlon - Presentes com até 60% de Desconto"
        description="Natal na Decathlon! Compre roupas, calçados, equipamentos, acessórios, artigos esportivos e presentes com até 60% de desconto"
        noindex
        nofollow
      />
      <NatalComponent />
    </>
  )
}

export default natal
